<template>
    <div class="huge-demo">
        <div class="tree-wrap" v-show="isShowDialog">
            <big-tree
                ref="huge-tree"
                hasInput
                checkedAction="dblclick"
                expandLevel="all"
                :isLoading="isLoading"
                :defaultCheckedKeys="checkedKeys"
                @onChange="onChange"
                @onClickLabel="onClickLabel"
                @onClickCheckbox="onClickCheckbox"
            >
                <select slot="pre-input" class="pre-select">
                    <option value="1">1111</option>
                    <option value="2">2222</option>
                </select>
                <span slot-scope="{ slotScope }">abc<i>&#9733;</i> {{ slotScope.label }}</span>
                <i slot="loading">加载中...</i>
            </big-tree>
        </div>
    </div>
</template>

<script>
import BigTree from './big-tree.vue';
import data500 from './tree-500';

export default {
    components: {
        BigTree,
    },
    props: {},
    data() {
        return {
            checkedKeys: [],
            isLoading: true,
            isShowDialog: false,
            data: [],
            expandKeys: [],
            // data: [
            //    {
            //       checked: false,
            //       id: 1,
            //       indeterminate: false, // 节点的子树中是否是部分选中， 代码生成
            //       label: '节点text'
            //       parentId: 0, // 父节点 id
            //       isLeaf: false, // 叶子节点
            //       path: [0, 1], // 节点位置
            //       isExpand: false, // 展开， 代码生成
            //       hidden: false, // 隐藏， 代码生成
            //       disabled: false, // 禁用， 可选
            //       leafCount: 100,  // 子、孙元素的数量，代码计算
            //     }
            // ],
        };
    },

    computed: {},

    mounted() {
        this.btnClick();
    },
    methods: {
        btnClick() {
            this.isShowDialog = true;
            this.$refs['huge-tree'].setData(data500);
        },
        onChange({checkedKeys, checkedNodes}) {
            // console.log('onChange', checkedKeys, checkedNodes);
        },
        onClickLabel(node) {
            // console.log('onClickLabel', node);
        },
        onClickCheckbox(node) {
            // console.log('onClickCheckbox', node);
        }
    },
};
</script>

<style scoped lang="less">
.huge-demo {
    text-align: left;

    .btn-bar {
        padding: 20px;

        button {
            cursor: pointer;
            padding: 5px 10px;
            color: #ffffff;
            background-color: #409eff;
            border-radius: 4px;

            &:hover {
                background-color: #228af1;
            }
        }
    }

    .tree-wrap {
        height: 600px;
        width: 400px;

        .pre-select {
            cursor: pointer;
            padding: 5px 10px;
        }
    }
}
</style>
